<template>
  <v-card>
    <v-card-title class="d-flex flex-column align-start">
      <span class="headline">Edição Rápida</span>
      <span class="text-caption">Agendamento: {{ agendamento ? agendamento.public_id : 'Agendamento não selecionado' }}</span>
    </v-card-title>
    <v-card-text class="">
      <v-row>
        <v-col class="12" cols="12" sm="12" lg="4" md="4">
          <seleciona-empresa
            label="Cliente"
            placeholder="Digite aqui para pesquisar!"
            :items="theClientes"
            v-model="agendamento.cliente"
            @keyup.native="customChange($event, handleGetClientes)"
            @change="handleGetCliente"
          />
        </v-col>
        <v-col cols="12" sm="12" lg="4" md="4">
          <seleciona-empresa
            label="Transportadora"
            :items="theTransportadoras"
            v-model="agendamento.transportadora"
          />
        </v-col>
        <v-col cols="12" sm="12" lg="2" md="2">
          <v-dialog v-model="dialog" max-width="700px">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="my-2" v-on="on"
                >Adicionar Navio
              </v-btn>
            </template>
            <form-navio
              v-model="novoNavio"
              :navio="agendamento.navio"
              :gate="agendamento.gate"
              :formTitle="formTitle"
              @close="close"
              @save="saveNavio"
              :navioIndex="navioIndex"
            />
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <dados-motorista @addedMotorista="addedMotorista"/>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" lg="3" md="3">
          <v-btn
            color="black"
            class="text-capitalize"
            dark
            @click="handleEntradaBuffer(agendamento)"
            >Entrada Buffer</v-btn
          >
        </v-col>
        <v-col cols="12" sm="12" lg="1" md="1">
          <v-btn
            color="black"
            class="text-capitalize"
            dark
            @click="handleSaidaBuffer(agendamento)"
            >Saída Buffer</v-btn
          >
        </v-col>

        <v-col cols="12" sm="12" lg="4" md="4"></v-col>

        <v-col cols="12" sm="12" lg="2" md="2">
          <v-btn color="primary" class="px-4" @click="saveEdicaoRapida">Salvar</v-btn>
        </v-col>
        <v-col cols="12" sm="12" lg="2" md="2">
          <v-btn
            color="black"
            class="text-capitalize"
            dark
            @click="closeEdicaoRapida()"
            >Cancelar</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { toLocaleDateTimeString } from '@/mixins/convertion'
import DadosMotorista from '@/components/patio/NovoAgendamento/Etapas/DadosMotorista.vue'
import SelecionaEmpresa from '@/components/patio/NovoAgendamento/Etapas/SelecionaEmpresa.vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import FormNavio from '@/components/patio/NovoAgendamento/Etapas/FormNavio.vue'
import { customChange } from '@/mixins/autocomplete.js'

export default {
  components: {
    DadosMotorista,
    FormNavio,
    SelecionaEmpresa,
  },
  mixins: [customChange],
  props: {
    agendamentoEdicaoRapida: {
      type: Object,
      required: true,
    },
  },
  filters: { toLocaleDateTimeString },
  data() {
    return {
      dialog: false,
      formTitle: 'Adicionar Navio',
      consultados: { cliente: false },
      navioIndex: -1,
      novoNavio: {},
    }
  },
  methods: { 
    ...mapActions('agendamentos', [
      'updateAtributoAgendamento',
      'updateAtributo',
      'atualizaGatesAgendamento',
      'atualizaFornecedoresAgendamento',
      'atualizaClientesAgendamento',
      'buscaClientesAgendamento',
      'atualizaTransportadorasAgendamento',
      'atualizaTerminaisAgendamento',
      'atualizaEstacionamentoAgendamento',
      'concatTransportadoras',
      'buscaClienteAgendamento',
    ]),
    ...mapActions('patio', [
      'cobranca_adicional',
      'nextStep',
      'previousStep',
      'cancelarAgendamento',
      'startPauseAgendamento',
      'stopPauseAgendamento',
      'resetarClassificacao',
      'finalizaClassificacao',
      'confirmaSaidaIport',
      'enviarComandoCancela',
      'enviaClassificacao',
      'enviaPortoNet',
      'cadastraMotoristaRonda',
      'confirmaChegadaIport',
      'estornarStatusInicial',
      'autorizarAgendamento',
      'estornarCancelamento',
      'recusarAgendamento',
      'saidaBuffer',
      'updateFast',
      'entradaBuffer',
      'getLaudoLimpezaAgendamento',
      'getItensLaudoCheckList',
      'getAgendamentoGerenciamentoServico',
      'getMotivosCancelamento',
      'addLaudoItemCheckList',
      'editLaudoItemCheckList',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    ...mapMutations('agendamentos', ['ATUALIZA_AGENDAMENTO']),
    ...mapMutations('solicitacaoBaixa', ['SET_TERMINAIS']),
    ...mapActions('solicitacaoBaixa', [
      'getAgendamentosEmPatio',
      'addSolicitacaoBaixa',
    ]),
    async handleGetClientes(event) {
      const value = event.target.value
      await this.buscaClientesAgendamento({
        fornecedor: this.empresaAtual.public_id,
        pesquisa: value,
      })
    },
    clienteConsultado() {
      this.consultados.cliente = true
    },
    resetaClienteConsultado() {
      this.consultados.cliente = false
    },
    async getAgendamento(agendamento) {
      return await this.getAgendamentoGerenciamentoServico(agendamento)
    },
    async handleGetCliente() {
      if (!(this.agendamento.cliente && this.agendamento.cliente.public_id))
        return
      if (this.consultados.cliente) {
        this.resetaClienteConsultado()
        return
      }
      const cliente = await this.buscaClienteAgendamento({
        empresa: this.empresaAtual,
        cliente: this.agendamento.cliente,
      })
      this.clienteConsultado()
      this.updateAtributoAgendamento({
        valor: cliente,
        nomeAtributo: 'cliente',
      })
    },
    async addedMotorista(motorista) {
      console.log(motorista)
      this.agendamento.motorista = motorista
    },
    closeEdicaoRapida() {
      this.$emit('close')
    },
    close() {
      this.dialog = false
    },
    saveNavio() {
      this.updateAtributoAgendamento({
        valor: this.novoNavio,
        nomeAtributo: 'navio',
      })
    },
    async handleEntradaBuffer(item) {
      try {
        const agendamentoEntradaBuffer = await this.getAgendamento(item)
        const data = await this.entradaBuffer({
          agendamento: agendamentoEntradaBuffer,
        })
        this.successMessage(data.statusMessage)
      } catch (error) {
        console.log(error)
        window.error = error
        if (error.response) {
          this.errorMessage(error.response.data)
        } else {
          this.errorMessage(error)
        }
      }
    },
    saveEdicaoRapida() {
     
        const agendamento = {
          "public_id": this.agendamento.public_id,
          "navio": this.agendamento.navio.item.id,
          "cliente": this.agendamento.cliente.public_id,
          "transportadora": this.agendamento.transportadora.public_id,
          "motorista": this.agendamento.motorista.public_id,
        }
        const data = this.updateFast({
          agendamento: agendamento,
        })
        this.successMessage(data.statusMessage)
        this.$emit('close')
    },
    async handleSaidaBuffer(item) {
      try {
        const agendamentoSaidaBuffer = await this.getAgendamento(item)
        const data = await this.saidaBuffer({
          agendamento: agendamentoSaidaBuffer,
        })
        this.successMessage(data.statusMessage)
      } catch (err) {
        this.errorMessage(err)
      }
    },
   },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('agendamentos', [
      'fornecedores',
      'clientes',
      'agendamento',
      'gates',
      'transportadoras',
      'terminais',
      'produtos',
      'subProdutos',
      'tipoAgendamento',
      'pedidoSelecionado',
      'agendamentoSelecionado',
      'programacaoUnidade',
      'programacaoFornecedor',
      'programacaoRegiao',
      'programacaoCliente',
      'estacionamentos',
    ]),
    theClientes() {
        return []
          .concat(this.clientes, this.agendamento.cliente)
          .filter((item) => item)
    },

    theTransportadoras() {
      return this.transportadoras
    },
  },
  async created() {
    this.ATUALIZA_AGENDAMENTO(this.agendamentoEdicaoRapida)
    await this.atualizaTransportadorasAgendamento(this.empresaAtual.public_id)
  }
}
</script>

<style>
.v-timeline-item:last-child {
  padding-bottom: 0 !important;
}
</style>
<style lang="scss" scoped>
.v-card {
  .v-card__text {
    padding-bottom: 0;
  }
}
</style>
