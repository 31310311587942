<template>
  <v-row>
    <v-col class="12">
      <v-card class="mb-2">
        <v-card-text class="pa-2 border-bottom">
          <h3
            class="subtitle blue-grey--text text--darken-2 font-weight-regular"
          >
            Dados do Motorista
          </h3>
        </v-card-text>
        <v-card-text class="pa-5 border-bottom">
          <v-form id="formDadosMotorista">
            <v-row align="center">
              <v-col class="pt-0" cols="12" sm="12" lg="2" md="2">
                <validation-provider
                  v-slot="{ errors }"
                  name="tipoDoc"
                  rules="required"
                >
                  <v-autocomplete
                    v-model="agendamento.motorista.nacionalidade"
                    :items="nacionalidades"
                    :error-messages="errors"
                    item-text="pais"
                    item-value="pais"
                    label="Nacionalidade"
                  />
                </validation-provider>
              </v-col>
              <v-col class="pt-0" cols="12" sm="12" lg="2" md="2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Documento"
                  rules="required"
                >
                  <v-text-field
                    label="Documento"
                    v-model="agendamento.motorista.user.cpf"
                    :error-messages="errors"
                    v-mask="docMask"
                    @change="atualizaMotorista()"
                  />
                </validation-provider>
              </v-col>
              <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                <v-text-field
                  readonly
                  class="custom-label-color-black"
                  label="Motorista"
                  v-model="agendamento.motorista.user.name"
                />
              </v-col>
              <v-col class="pt-0" cols="12" sm="6" lg="2" md="2">
                <v-text-field
                  label="Celular"
                  class="custom-label-color"
                  readonly
                  v-model="agendamento.motorista.user.celular"
                  v-mask="['(##) #####-####', '(##) ####-####']"
                />
              </v-col>
              <v-col class="pt-0" cols="12" sm="6" lg="2" md="2">
                <v-text-field
                  label="CNH"
                  readonly
                  v-model="agendamento.motorista.user.cnh_registro"
                  v-mask="['###############']"
                />
              </v-col>
              <v-col class="pt-0" cols="12" sm="12" lg="1" md="1">
                <modal-motorista
                  @addedMotorista="addedMotorista"
                  :motoristaExistente="agendamento.motorista"
                /> </v-col
            ></v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { cpf } from 'cpf-cnpj-validator'
import ModalMotorista from '@/components/administrativo/motorista/ModalMotorista'
import PatioAPI from '@/services/patio'

export default {
  components: { ModalMotorista },
  data() {
    return {
      nacionalidade: 'Brasil',
      nacionalidades: [
        { pais: 'Brasil' },
        { pais: 'Paraguai' },
        { pais: 'Argentina' },
        { pais: 'Uruguay' },
      ],
    }
  },
  computed: {
    ...mapState('agendamentos', ['agendamento']),

    docMask() {
      switch (this.agendamento.motorista.user.nacionalidade) {
        case 'Brasil':
          return '###.###.###-##'
        case 'Paraguai':
          return '#######'
        case 'Argentina':
          return '##.###.###'
        case 'Uruguay':
          return '#.###.###.#'
        default:
          return '###########'
      }
    },
  },
  methods: {
    ...mapActions('agendamentos', [
      'informacoesMotorista',
      'verificarAgendamentoEmAbertoMotorista',
    ]),
    ...mapActions('template', ['errorMessage']),
    async addedMotorista(motorista) {
      this.agendamento.motorista = motorista
      await this.verificarMotorista()
    },
    async verificarMotorista() {
      try {
        if (this.agendamento.motorista.user?.cpf) {
          await PatioAPI.verificarAgendamentoEmAbertoMotorista(this.agendamento)
        }
      } catch (error) {
        this.errorMessage(error.response ? error.response.data : error)

        if (error.response && error.response.status === 400) {
          this.agendamento.motorista.user.cpf = ''
          this.agendamento.motorista.user.name = ''
          this.agendamento.motorista.user.celular = ''
          this.agendamento.motorista.user.cnh_registro = ''
        }
      }
    },
    async verificarBlackList() {
      try {
        await PatioAPI.verificarBlackList(this.agendamento)
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.errorMessage(error.response ? error.response.data : error)
          this.agendamento.motorista.user.cpf = ''
          this.agendamento.motorista.user.name = ''
          this.agendamento.motorista.user.celular = ''
          this.agendamento.motorista.user.cnh_registro = ''
        }
      }
    },
    async atualizaMotorista() {
      let cpfMotorista = this.agendamento.motorista.user.cpf

      // if (this.agendamento.tipo_operacao == null) {
      //   this.agendamento.motorista.user.cpf = ''
      //   this.errorMessage('Favor preencher o tipo de operação!')
      // } else {
        if (cpfMotorista) {
          if (this.nacionalidade === 'Brasil') {
            cpfMotorista = cpf.strip(cpfMotorista)
          } else {
            cpfMotorista = cpfMotorista.replaceAll('.', '')
          }
          const motorista = await this.informacoesMotorista(cpfMotorista)

          if (motorista == undefined) {
            this.agendamento.motorista.user.cpf = ''
            this.errorMessage(
              'Motorista sem cadastro, favor cadastrar o motorista no Menu: (Administrativo > Motoristas).'
            )
          } else {
            this.verificarBlackList()
            this.verificarMotorista()
          }
        }
      // }
    },
  },
}
</script>

<style>
.custom-placeholer-color input::placeholder {
  color: red !important;
  opacity: 1;
  font-weight: bold;
}

.custom-label-color .v-label {
  color: black;
  font-weight: bold;
  opacity: 1;
}

.custom-placeholer-color input,
.custom-label-color input {
  color: red !important;
  font-weight: bold;
}

.custom-placeholer-color-black input::placeholder {
  color: black !important;
  opacity: 1;
  font-weight: bold;
}

.custom-label-color-black .v-label {
  color: black;
  font-weight: bold;
  opacity: 1;
}

.custom-placeholer-color-black input,
.custom-label-color-black input {
  color: black !important;
  font-weight: bold;
}
</style>
